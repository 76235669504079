<template>
  <div class="website">
    <div class="title">
      <img src="../../../assets/img/wang/tip.png" alt="" />
      <span>微博信息</span>
    </div>
    <el-row :gutter="20">
      <el-form
        :model="weiboForm"
        :rules="rules"
        @submit.native.prevent
        ref="websiteForm"
        label-width="200px"
      >
        <!-- <el-col :span="11">
          <el-form-item label="账号名称ID" >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.accountId"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="11">
          <el-form-item label="用户名称" prop="accountName">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.accountName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="主页地址" prop="homeUrl">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.homeUrl"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所属地区" >
            <el-cascader :placeholder="ownRegionplaceholder" :props="ownRegionProps" v-model="ownRegion"   class="select diquClass" :options="ownRegionList" clearable>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="注册时间">
            <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              class="time-select"
              v-model="weiboForm.registeTime"
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="认证情况" prop="codeType">
            <el-select
              class="select"
              v-model="weiboForm.isAuthenticate"
              placeholder="请选择"
            >
              <el-option
                v-for="item in authenticationInfoOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="粉丝数量">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model.number="weiboForm.fans"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="关注数量">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model.number ="weiboForm.attention"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="标签信息">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.label"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="11">
          <el-form-item label="简介" >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.briefIntroduction"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="最后更新时间" >
            <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              class="time-select"
              v-model="weiboForm.lastUpdateTime"
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="发文数量">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model.number ="weiboForm.contentCount"
            ></el-input>
          </el-form-item>
        </el-col>
       
        <el-col :span="11">
          <el-form-item label="电话号码" >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.bindPhone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="关键词" >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weiboForm.label"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { cityList, districtList } from "../../../api/login/index";
export default {
  data() {
    return {
      rules: {
        accountName: [
          { required: true, message: "请输入账号名称", trigger: "blur" },
        ], //账号名称
        homeUrl: [
          { required: true, message: "请输入首页地址", trigger: "blur" },
        ], //首页地址
      },
         //网站信息表单
      weiboForm: {
        // accountId: null, //账号名称ID
        accountName:'',
        homeUrl:null, //主页地址
        provinceId: null, //所属省份
        cityId: null, //所属城市
        districtId: null, //所属区县
        registeTime: null, //注册时间
        isAuthenticate: null, //认证情况
        fans: null, // 粉丝数量
        attention: null, //关注数量
        label: null, //标签信息
        briefIntroduction: null, //简介
        lastUpdateTime: null, //最后更新时间 
        contentCount: null, //发文数量
        nickName: null, //微博昵称
        bindPhone: null, //电话号码
        label:"" // 关键词
      },
        // 认证情况   
      authenticationInfoOptions: JSON.parse( window.localStorage.getItem("is_authenticate") )||  [],
      ownRegion: [], // 地区
      ownRegionplaceholder:'',
      ownRegionProps: {
        value: "value", // 自定义要映射的键名
        lazy: true, 
        multiple: false,
        checkStrictly: true,
        lazyLoad: this.ownRegionLoadNode,
      }, // 地区选择
      ownRegionList:JSON.parse( window.localStorage.getItem('ownRegionList'))  || [], // 所在地区配置
    };
  },
  async mounted() {
    await this.$main.mySetTimeOut(1000)
    this.ownRegionList = JSON.parse( window.localStorage.getItem('ownRegionList'))  || [] // 所在地区配置
    console.log(this.ownRegionList)
  },
  methods:{
    //机构所属区域
    async ownRegionLoadNode(node, resolve) {
      let data = {
        parentCode: node.data ? node.data.code : null,
      };
      let dat = [];
      if (node.level == 1) {
        let res = await cityList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });
      }
      if (node.level == 2) {
        let res = await districtList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
            lazy: -1,
          };
        });
      }
      resolve(dat);
    }, 
     // 回显数据
     setData(query) {
      console.log(query)
      Object.keys(this.weiboForm).forEach(key=> {
        this.weiboForm[key] =   query[key]
      })
      
      //   if(query.provinceId)  this.ownRegion[0] = Number(query.provinceId)
      //   if(query.cityId) this.ownRegion[1] =  Number(query.cityId)
      //   if(query.districtId)this.ownRegion[2] =Number(query.districtId)
      // console.log(this.ownRegion)
      this.ownRegionplaceholder = query.areaName;
        // this.ownRegion = this.ownRegion
        // console.log(this.ownRegion)
        // this.editClick(query.provinceId)
    },
    getData() {
      if(this.ownRegion){
        if(this.ownRegion[0]) {
          this.weiboForm.provinceId = this.ownRegion[0]
        }else{
          this.weiboForm.provinceId = null
        }
        if(this.ownRegion[1]) {
          this.weiboForm.cityId =this.ownRegion[1]
        }else{
          this.weiboForm.cityId =null
        }
        if(this.ownRegion[2]) {
          this.weiboForm.districtId =this.ownRegion[2]
        }else{
          this.weiboForm.districtId =null
        }
      }
      let ifaaa;
      this.$refs["websiteForm"].validate((valid) => {
        ifaaa = valid;
      });
      if (ifaaa)    return this.weiboForm
      if (!ifaaa) return false;
    },
    editClick(id) {
   
  //  this.dialogFormVisible = true

   this.ownRegion = this.changeDetSelect(id,this.ownRegionList)
 
 },
    // 递归处理分类回显问题
// key就是接口拿到的值,treeData是全部数据
  changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },
  }
};
</script>

<style lang="scss" scoped >
.diquClass {
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #333333;
  }
}
.website {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 574px;
  height: auto;
  padding-bottom: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  .title {
    display: flex;
    height: 22px;
    margin: 25px 0px 31px 19px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
    }
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
  .input {
    //输入框
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      height: 36px;
      width: 660px;
    }
  }
  .time-select {
    width: 660px;
    height: 36px;
    margin: 0;
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
  //表单
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .el-form-item__label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 33px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .el-form-item__content {
      margin-left: 33px !important;
    }
  }
  ::v-deep .el-col.el-col-24 {
    height: 148px;
  }
  .select {
    //下拉选择
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      width: 660px;
      height: 36px;
    }
  }
}
</style>