<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="../../assets/img/wang/back.png" @click="back" alt="" />
        <span>{{ $route.query.isEdit ?'编辑账号':"新增账号" }} </span>
      </div>
      <div class="head-right">
        <div class="cancle" @click="back">取消</div>
        <div class="enter" @click="determine">确定</div>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <!-- 选择平台 -->
      <div class="select-platform">
        <span>选择添加平台</span>
        <el-select 
          :disabled="$route.query.isEdit ? true : false"
          class="select"
          @change="selectPage"
          v-model="platform"
          placeholder="请选择"
        >
          <el-option
            v-for="item in PlatformObj.checkedData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-loading="loading">
        <!-- 网站信息 -->
        <website ref="wangzhan" v-show="platform == '1'"></website>
        <!-- 备案信息 -->
        <beian ref="beian" v-show="platform == '1'"></beian>
        <!-- 微博信息 -->
        <Weibo ref="weibo" v-show="platform == '2'"> </Weibo>
        <!-- 微信公众号 -->
        <Weixin ref="weixin" v-show="platform == '3'"></Weixin>
        <!-- 公共信息 -->
        <Gg ref="Gg4" :Desctitle="DesctitleObj[platform]" v-show="platform == '4'"></Gg>
        <Gg ref="Gg5" :Desctitle="DesctitleObj[platform]" v-show="platform == '5'"></Gg>
        <Gg ref="Gg6" :Desctitle="DesctitleObj[platform]" v-show="platform == '6'"></Gg>
        <Gg ref="Gg7" :Desctitle="DesctitleObj[platform]" v-show="platform == '7'"></Gg>
        <Gg ref="Gg8" :Desctitle="DesctitleObj[platform]" v-show="platform == '8'"></Gg>
        <Gg ref="Gg9" :Desctitle="DesctitleObj[platform]" v-show="platform == '9'"></Gg>
        <Gg ref="Gg10" :Desctitle="DesctitleObj[platform]" v-show="platform == '10'"></Gg>
        <Gg ref="Gg11" :Desctitle="DesctitleObj[platform]" v-show="platform == '11'"></Gg>
        <Gg ref="Gg12" :Desctitle="DesctitleObj[platform]" v-show="platform == '12'"></Gg>
        <Gg ref="Gg13" :Desctitle="DesctitleObj[platform]" v-show="platform == '13'"></Gg>
        <Gg ref="Gg14" :Desctitle="DesctitleObj[platform]" v-show="platform == '14'"></Gg>
        <Gg ref="Gg15" :Desctitle="DesctitleObj[platform]" v-show="platform == '15'"></Gg>
        <Gg ref="Gg16" :Desctitle="DesctitleObj[platform]" v-show="platform == '16'"></Gg>
        <Gg ref="Gg17" :Desctitle="DesctitleObj[platform]" v-show="platform == '17'"></Gg>
        <Gg ref="Gg18" :Desctitle="DesctitleObj[platform]" v-show="platform == '18'"></Gg>
        <Gg ref="Gg19" :Desctitle="DesctitleObj[platform]" v-show="platform == '19'"></Gg>
        <Gg ref="Gg20" :Desctitle="DesctitleObj[platform]" v-show="platform == '20'"></Gg>
        <Gg ref="Gg21" :Desctitle="DesctitleObj[platform]" v-show="platform == '21'"></Gg>
        <Gg ref="Gg22" :Desctitle="DesctitleObj[platform]" v-show="platform == '22'"></Gg>
        <!-- 工商信息 -->
        <!-- <gongShang 
      v-for="index in 22"
      :key="index"
      v-show="platform == String(index+1)" 
      :ref='`gongShang${index}`'
      ></gongShang> -->
        <!-- 工商信息 多抽组件是好事  不然把人写死在这儿 -->
        <gongShang v-show="platform == '1'" ref="gongShang1"></gongShang>
        <gongShang v-show="platform == '2'" ref="gongShang2"></gongShang>
        <gongShang v-show="platform == '3'" ref="gongShang3"></gongShang>
        <gongShang v-show="platform == '4'" ref="gongShang4"></gongShang>
        <gongShang v-show="platform == '5'" ref="gongShang5"></gongShang>
        <gongShang v-show="platform == '6'" ref="gongShang6"></gongShang>
        <gongShang v-show="platform == '7'" ref="gongShang7"></gongShang>
        <gongShang v-show="platform == '8'" ref="gongShang8"></gongShang>
        <gongShang v-show="platform == '9'" ref="gongShang9"></gongShang>
        <gongShang v-show="platform == '10'" ref="gongShang10"></gongShang>
        <gongShang v-show="platform == '11'" ref="gongShang11"></gongShang>
        <gongShang v-show="platform == '12'" ref="gongShang12"></gongShang>
        <gongShang v-show="platform == '13'" ref="gongShang13"></gongShang>
        <gongShang v-show="platform == '14'" ref="gongShang14"></gongShang>
        <gongShang v-show="platform == '15'" ref="gongShang15"></gongShang>
        <gongShang v-show="platform == '16'" ref="gongShang16"></gongShang>
        <gongShang v-show="platform == '17'" ref="gongShang17"></gongShang>
        <gongShang v-show="platform == '18'" ref="gongShang18"></gongShang>
        <gongShang v-show="platform == '19'" ref="gongShang19"></gongShang>
        <gongShang v-show="platform == '20'" ref="gongShang20"></gongShang>
        <gongShang v-show="platform == '21'" ref="gongShang21"></gongShang>
        <gongShang v-show="platform == '22'" ref="gongShang22"></gongShang>
      </div>
    </div>
  </div>
</template>

<script>
import website from "./new/website.vue";
import beian from "./new/beian.vue";
import gongShang from "./new/gongShang.vue";
import Weibo from "./new/weibo.vue";
import Weixin from "./new/weixin.vue";
import Gg from "./new/gg.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import { addaccount ,update,upbusinessInfo} from "@/api/platformaccount/index";
import { provinceList } from "../../api/login/index";
export default {
  components: { Breadcrumb, website, beian, gongShang, Weibo, Weixin, Gg },
  data() {
    return {
      loading: true,
      routerList: [],
      // 平台类型
      PlatformObj: {
        checkAll: false, // 平台类型全选
        checkedform: [], //平台类型已选
        checkedData:
          JSON.parse(window.localStorage.getItem("platform_type")) || [], //平台类型 可选数据
        isIndeterminate: false, //平台类型不确定状态
      },
      platform:this.$route.query.platformType ||  "1", //平台
      DesctitleObj:{}  // 给其他19个页面储存标题
    };
  },
  created() {
    JSON.parse(window.localStorage.getItem("platform_type")).forEach(v=> {
      this.DesctitleObj[v.value ] = v.label
    })
      this.provinceList()
  },
  destroyed() {
    window.localStorage.removeItem("ownRegionList");
  },
  mounted() {
    const {query} = this.$route
    console.log(query);
    const {isEdit,platformType} = query
    this.routerList = ["平台台账", isEdit ?"编辑账号":'新增账号']
    // 下面是回显
    if(isEdit) {
      this.$refs['wangzhan'].setData(query) // 网站
    this.$refs['beian'].setData(query) // 备案是网站独有的
    this.$refs['weibo'].setData(query) // 微博
    this.$refs['weixin'].setData(query) // 微信
    if(platformType > 3) {
      this.$refs[`Gg${platformType}`].setData(query) //除了 网站 微博 微信 这三个之外的的
    }
   console.log( JSON.parse( query.businessInfoList))
    const businessInfoList = query.businessInfoList || []
    if(businessInfoList && Object.keys(businessInfoList).length > 0) this.$refs[`gongShang${platformType}`].setData(businessInfoList) // 工商 所有都有的
    }
    setTimeout(()=> {this.loading=false},500)
  },
  methods: {
    //初始化地区列表
    async provinceList() {
      let res = await provinceList({});
      // 所属区域数据
      const ownRegionList = res.data.data.map((x) => {
        return {
          label: x.name,
          value: x.id,
          code: x.code,
        };
      });
      window.localStorage.setItem(
        "ownRegionList",
        JSON.stringify(ownRegionList)
      );
    },
    //  切换平台时候
    async selectPage() {
      this.loading = true;
      await this.$main.mySetTimeOut(500);
      this.loading = false;
    },
    //返回
    back() {
      this.$router.go(-1);
    },
    //  确定
    async determine() {
      const { platform, $refs } = this;
      let obj = {};
      const wangzhanData = platform == '1' ? $refs.wangzhan.getData():null;
      const weiboData =platform == "2"? $refs.weibo.getData():null
      const weixinData = platform == '3'? $refs.weixin.getData():null
      let  GgData; //获取公共信息
      for(let i =4;i<23;i++) {
        if(platform == i+'') GgData = $refs[`Gg${platform}`].getData()
      }
      let gongShangData={} // 获取工商表单的数据
      for(let i =1;i<23;i++) {
        if(platform == i+'') gongShangData = $refs[`gongShang${platform}`].getData() || {} ; // 获取工商表单的数据
        console.log(gongShangData,'工商信息',platform);
        console.log(gongShangData && Object.keys(gongShangData).filter(v=> gongShangData[v]).length > 0,'是对还是错');
      }
      //           判断阶段
      // 网站
      if (!wangzhanData && platform == "1") return;
      // 如果工商信息存在  但是 统一社会信用代码没有输入  那么不给过
      if(gongShangData &&  gongShangData.aaa ) return
      delete gongShangData.aaa
      // 微博 
      if(!weiboData  && platform == "2") return 
      // 微信 
      if(!weixinData && platform == '3') return
      // 公共
      let ifGg=false
      for(let i = 4;i< 23;i++) {
        if(platform == i+'' && !GgData) {
          ifGg= true 
          return
        }
      }
      if(ifGg) return
      this.loading = true;
      //           配置阶段
      // 公共信息
      if (platform !== "1" && platform !== "2" && platform !== "3")
        obj = { ...GgData,
          // businessInfo: gongShangData
         };
      // 网站
      if (platform == "1") obj = { 
        // businessInfo:gongShangData,
        ...wangzhanData,...$refs.beian.getData() };
      // 微博
      if (platform == "2") obj = { ...weiboData, 
        // businessInfo:gongShangData 
      };
      // 微信公众号
      if (platform == "3") obj = { ...weixinData, 
        // businessInfo:gongShangData
       };
      // return
      try{
        const editObj ={
          ...obj, 
          platformType: platform,
          accountId:this.$route.query.accountId
        }
        // if(gongShangData && Object.keys(gongShangData).filter(v=> gongShangData[v]).length > 0) editObj.socialCreditCode = gongShangData.socialCreditCode
        editObj.socialCreditCode = gongShangData.socialCreditCode//修改不填写工商统一社会信用代码
        const Res=  this.$route.query.isEdit
        ? await update(editObj) 
        : await addaccount({ ...obj, platformType: platform })
        if(Res.data.code == 200) {
          this.$message.success(this.$route.query.isEdit ? "编辑账号":"新增账号成功")
          if(gongShangData && Object.keys(gongShangData).filter(v=> gongShangData[v]).length > 0) {
            await upbusinessInfo(gongShangData)
          }
          this.back() 
        }
        this.loading = false;
      }catch{
        this.loading = false;
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .head-right {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      width: calc(50% - 228px);
      align-items: center;
      .cancle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 36px;
        background: #fff1e8;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ed731f;
        margin-right: 16px;
        cursor: pointer;
      }
      .enter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        margin-right: 24px;
      }
    }
  }
  .page {
    padding-top: 72px;
    .select-platform {
      display: flex;
      align-items: center;
      width: 100%;
      height: 64px;
      background: #ffffff;
      border-radius: 8px;
      margin: 16px 0;
      span {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0px 16px 0px 24px;
      }
      .select {
        //下拉选择
        width: 280px;
        height: 36px;
        margin: 0px 24px 0 8px;
        ::v-deep .el-input__inner {
          width: 280px;
          height: 36px;
          border-radius: 4px;
        }
      }
    }
    .website {
      display: flex;
      flex-direction: column;
      width: 100%;
      // min-height: 574px;
      height: auto;
      padding-bottom: 32px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 16px;
      .title {
        display: flex;
        height: 22px;
        margin: 25px 0px 31px 19px;
        img {
          height: 22px;
          width: 22px;
          margin-right: 3px;
        }
        span {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
        }
      }
      .input {
        //输入框
        width: 660px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 660px;
        }
      }
      .time-select {
        width: 660px;
        height: 36px;
        margin: 0;
        ::v-deep .el-input__inner {
          height: 36px;
        }
      }
      //表单
      ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        .el-form-item__label {
          display: flex;
          align-items: center;
          height: 48px;
          padding-left: 33px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .el-form-item__content {
          margin-left: 33px !important;
        }
      }
      ::v-deep .el-col.el-col-24 {
        height: 148px;
      }
      .select {
        //下拉选择
        width: 660px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 660px;
          height: 36px;
        }
      }
    }
  }
}
</style>