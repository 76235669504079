<template>
  <div class="website">
    <div class="title">
      <img src="../../../assets/img/wang/tip.png" alt="" />
      <span>{{Desctitle.trim()}}信息</span>
    </div>
    <el-row :gutter="20"> 
      <el-form
        :model="weixinForm"
        @submit.native.prevent
        :rules="rules"
        ref="websiteForm"
        label-width="200px"
      >
        <el-col :span="11">
          <el-form-item label="作者名称" prop="accountName">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.accountName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="粉丝数量" >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.fans"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11">
          <el-form-item label="作者名称 ---" >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.accountName"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="11">
          <el-form-item label="关注数量">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.attention"
            ></el-input>
          </el-form-item> </el-col
        ><el-col :span="11">
          <el-form-item label="简介">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.introduction"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="发文数量">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.contentCount"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所属地区" prop="websiteDomin">
            <el-cascader :placeholder="ownRegionplaceholder"  :props="ownRegionProps" v-model="ownRegion"  class="select diquClass" :options="ownRegionList" clearable>
            </el-cascader>
          </el-form-item>
          <!-- <el-form-item label="所属地区">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.areaName"
            ></el-input>
          </el-form-item> -->
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { cityList, districtList } from "../../../api/login/index";
export default {
  props: {
    Desctitle:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      rules: {
        accountName: [
          { required: true, message: "请输入账号名称", trigger: "blur" },
        ], //网站名称
      },
      //网站信息表单
      weixinForm: {
        accountId: null, //账号名称ID
        accountName:null,  //作者名称
        fans:null, //粉丝
        attention:null, //关注数量
        introduction:null,        //简介
        contentCount:null, // 发文数量
        provinceId:null ,// 省份
        cityId:null ,// 市
        districtId: null, // 县
      },
      // 认证情况
      authenticationInfoOptions: [
        { value: 1, label: "普通账号" },
        { value: 2, label: "微博达人" },
        { value: 3, label: "个人认证" },
        { value: 4, label: "机构认证" },
      ],
      ownRegion: [], // 地区
      ownRegionplaceholder:'',
      ownRegionProps: {
        lazy: true,
        multiple: false,
        checkStrictly: true,
        lazyLoad: this.ownRegionLoadNode,
      }, // 地区选择
      ownRegionList:JSON.parse( window.localStorage.getItem('ownRegionList')) || [], // 所在地区配置
      provanceOptions: [
        { label: "陕西省", value: "0" },
        { label: "河北省", value: "1" },
      ], //所属省份
      cityOptions: [
        { label: "西安市", value: "0" },
        { label: "南京市", value: "1" },
      ], //所属城市
      districtOptions: [
        { label: "镇平县", value: "0" },
        { label: "佛坪县", value: "1" },
      ], //所属区县
      disposeOption: [
        { value: 1, label: "无违规账号" },
        { value: 2, label: "处置中" },
        { value: 3, label: "处置完毕" },
      ],
    };
  },
  async mounted() {
    await this.$main.mySetTimeOut(1000)
    this.ownRegionList=JSON.parse( window.localStorage.getItem('ownRegionList'))  ||  []// 所在地区配置
  },
  methods: {
    //机构所属区域
    async ownRegionLoadNode(node, resolve) {
      let data = {
        parentCode: node.data ? node.data.code : null,
      };
      let dat = [];
      if (node.level == 1) {
        let res = await cityList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });
      }
      if (node.level == 2) {
        let res = await districtList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
            lazy: -1,
          };
        });
      }
      resolve(dat);
    },
      // 回显数据
      setData(query) {
      Object.keys(this.weixinForm).forEach(key=> {
        this.weixinForm[key] =   query[key]
      })
      this.ownRegionplaceholder = query.areaName;
    },
    // 获取数据
    getData() {
      if(this.ownRegion){
        if(this.ownRegion[0]) this.weixinForm.provinceId = this.ownRegion[0]
        if(this.ownRegion[1]) this.weixinForm.cityId =this.ownRegion[1]
        if(this.ownRegion[2]) this.weixinForm.districtId =this.ownRegion[2]
      }
      let ifaaa;
      this.$refs["websiteForm"].validate((valid) => {
        ifaaa = valid;
      });
      if (ifaaa) return this.weixinForm
      if (!ifaaa) return false;
    }
  },
};
</script>

<style lang="scss" scoped >
  .diquClass {
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #333333;
  }
}
.website {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 574px;
  height: auto;
  padding-bottom: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  .title {
    display: flex;
    height: 22px;
    margin: 25px 0px 31px 19px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
    }
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
  .input {
    //输入框
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      height: 36px;
      width: 660px;
    }
  }
  .time-select {
    width: 660px;
    height: 36px;
    margin: 0;
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
  //表单
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .el-form-item__label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 33px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .el-form-item__content {
      margin-left: 33px !important;
    }
  }
  ::v-deep .el-col.el-col-24 {
    height: 148px;
  }
  .select {
    //下拉选择
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      width: 660px;
      height: 36px;
    }
  }
}
</style>